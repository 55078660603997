<template>
  <span>
    <span v-if="!loading">
      <section v-if="isCycleActive" id="dashboard-ecommerce">
        <cycle-edit @refetch-data="refetchCycleData" :is-edit-cycle-sidebar-active.sync="isEditCycleSidebarActive"
          :item="cycleData" />

        <b-row class="match-height">
          <b-col xl="4" md="6">
            <cycle @set-inactive="setInactive" :data="cycleData"
              @updateEditCycleSidebarective="updateEditCycleSideBarActive"
              @update-cycleData="updateCycleData" />
            <!-- <ecommerce-medal :data="data.congratulations" /> -->
          </b-col>
          <b-col xl="8" md="6">
            <user-statistics :data="statsData.users" />
            <survey-statistics :data="statsData.surveys" />
          </b-col>
        </b-row>
      </section>

      <section v-else id="dashboard-ecommerce">
        <b-row class="match-height mt-3">
          <b-col xl="4" md="4">
          </b-col>
          <b-col xl="4" md="4">
            <cycle-create @createCycleModal="handleCreateCycleModal" />
          </b-col>
        </b-row>
        <create-cycle-modal ref="createCycleModal" />
      </section>
    </span>
  </span>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'


import Cycle from './Cycle.vue'
import CycleEdit from './CycleEdit.vue'
import CycleCreate from './CycleCreate.vue'
import UserStatistics from './UserStatistics.vue'
import SurveyStatistics from './SurveyStatistics.vue'
import CreateCycleModal from './components/CreateCycleModal.vue'

export default {
  components: {
    BRow,
    BCol,
    Cycle,
    CycleEdit,
    CycleCreate,
    UserStatistics,
    SurveyStatistics,
    CreateCycleModal,
  },
  model: {
    prop: 'isEditCycleSidebarActive',
    event: 'update:is-edit-cycle-sidebar-active',
  },
  data() {
    return {
      loading: true,
      cycleData: {},
      statsData: {
        users: [],
        surveys: [],
      },
      isCycleActive: false,
      isEditCycleSidebarActive: false,
    }
  },
  beforeCreate() {
    this.$http.get('/cycles/isActive')
      .then(response => {
        this.isCycleActive = response.data.isActive
        this.loading = false
        if (this.isCycleActive) {
          this.$http.get('/cycles/active')
            .then(response => {
              this.cycleData = response.data
            })
        }
      })

    Promise.all([
      this.$http.get('/stats/user'),
      this.$http.get('/stats/survey'),
    ]).then(([userResponse, surveyResponse]) => {
      this.statsData.users = this.formatUserData(userResponse.data)
      this.statsData.surveys = this.formatSurveyData(surveyResponse.data)
    }).catch(error => {
      console.error('Error fetching data:', error)
    });
  },
  methods: {
    updateEditCycleSideBarActive() {
      this.isEditCycleSidebarActive = !this.isEditCycleSidebarActive
    },
    handleCreateCycleModal() {
      this.$refs.createCycleModal.show()
    },
    refetchCycleData(newCycleData) {
      this.cycleData = newCycleData
    },
    setInactive() {
      this.isCycleActive = false
    },
    updateCycleData(newCycleData) {
      this.cycleData = newCycleData;
    },
    formatUserData(usersData) {
      const { usersCount, activeUsersCount, unsubmittedUsersCount, unNominatedUsersCount, unNominatingUsersCount, usersWithSurveyCount } = usersData;

      return [
        {
          icon: "UserIcon",
          color: "light-success",
          title: usersCount,
          subtitle: "Total Users",
          customClass: ""
        },
        {
          icon: "UserCheckIcon",
          color: "light-primary",
          title: activeUsersCount,
          subtitle: "Active Users",
          customClass: "mb-2 mb-xl-0"
        },
        {
          icon: "UserXIcon",
          color: "light-info",
          title: unsubmittedUsersCount,
          subtitle: "Unsubmitted Users",
          customClass: "mb-2 mb-xl-0"
        },
        {
          icon: "UserMinusIcon",
          color: "light-danger",
          title: unNominatedUsersCount,
          subtitle: "Unnominated Users",
          customClass: "mb-2 mb-sm-0"
        },
        {
          icon: "UserPlusIcon",
          color: "light-success",
          title: unNominatingUsersCount,
          subtitle: "Unnominating Users",
          customClass: ""
        },
        {
          icon: "",
          color: "",
          title: usersWithSurveyCount,
          subtitle: "Users with surveys",
          customClass: ""
        }
      ];
    },
    formatSurveyData(surveysData) {
      const { surveysCount, submittedSurveysCount, unsubmittedSurveysCount } = surveysData;
      
      return [
        {
          icon: "TrendingUpIcon",
          color: "light-primary",
          title: surveysCount,
          subtitle: "Total Surveys",
          customClass: "mb-2 mb-xl-0"
        },
        {
          icon: "CheckSquareIcon",
          color: "light-info",
          title: submittedSurveysCount,
          subtitle: "Submitted Surveys",
          customClass: "mb-2 mb-xl-0"
        },
        {
          icon: "EditIcon",
          color: "light-danger",
          title: unsubmittedSurveysCount,
          subtitle: "Unsubmitted Surveys",
          customClass: "mb-2 mb-sm-0"
        }
      ];
    }
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>

<template>
  <b-card v-if="data" no-body class="card-developer-meetup">
    <b-card-body>
      <!-- meeting header -->
      <b-card-header>
        <div class="meetup-header d-flex align-items-center">
          <div class="meetup-day">
            <h6 class="mb-0">
              {{ monthName }}
            </h6>
            <h3 class="mb-0">
              {{ day }}
            </h3>
            <h5 class="mb-0">
              {{ year }}
            </h5>
          </div>
          <div class="my-auto">
            <b-card-title class="mb-25">
              {{ data.title }}
            </b-card-title>
            <b-card-text class="mb-0">
              {{ cycleStatus }}
            </b-card-text>
          </div>
        </div>
        <b-button class="mb-5 p-50" activev-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="outline-primary"
          @click="$emit('updateEditCycleSidebarective')">
          <feather-icon icon="EditIcon" size="16" />
        </b-button>
      </b-card-header>
      <!--/ meeting header -->

      <!-- media -->
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-primary" size="40">
            <feather-icon icon="CalendarIcon" size="20" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <small class="p-0 m-0">Start Date</small>
          <h6 class="mb-0">
            {{ formatDate(data.startDate) }}
          </h6>
        </b-media-body>
      </b-media>

      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar rounded variant="light-danger" size="40">
            <feather-icon icon="CalendarIcon" size="20" />
          </b-avatar>
        </b-media-aside>
        <b-media-body>
          <small class="p-0 m-0">Deadline</small>
          <h6 class="mb-0">
            {{ formatDate(data.deadline) }}
          </h6>
        </b-media-body>
      </b-media>
      <vue-apex-charts ref="completionChart" type="radialBar" height="200" class="mb-50"
        :options="chartOptions" :series="series" />
      <b-row class="text-center mx-0">
        <b-col cols="6" class="border-top border-right d-flex align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            Elapsed
          </b-card-text>
          <h4 class="font-weight-bolder mb-50">
            {{ daysElapsed }} day(s)
          </h4>
        </b-col>

        <b-col cols="6" class="border-top d-flex align-items-between flex-column py-1">
          <b-card-text class="text-muted mb-0">
            Left
          </b-card-text>
          <h4 class="font-weight-bolder mb-0">
            {{ daysLeft }} day(s)
          </h4>
        </b-col>
      </b-row>
      <b-row class="text-center mx-0 mt-50">
        <b-col >
          <!-- New Stop Nominations Button -->
          <!-- <b-button activev-ripple.400="'rgba(255, 255, 255, 0.15)'" :variant="data.nominationsStopped ? 'secondary' : 'warning'" @click="toggleNominations" :disabled="data.nominationsStopped">
            <feather-icon :icon="data.nominationsStopped ? 'CheckCircleIcon' : 'StopCircleIcon'" class="mr-50" size="16" />
            <span class="align-middle">{{ data.nominationsStopped ? 'Nominations Stopped' : 'Stop Nominations' }}</span>
          </b-button> -->
          <b-button 
            activev-ripple.400="'rgba(255, 255, 255, 0.15)'" 
            :variant="data.nominationsStopped ? 'success' : 'warning'" 
            @click="toggleNominations"
          >
            <feather-icon 
              :icon="data.nominationsStopped ? 'RefreshCwIcon' : 'StopCircleIcon'" 
              class="mr-50" 
              size="16" 
            />
            <span class="align-middle">{{ data.nominationsStopped ? 'Restore Nominations' : 'Stop Nominations' }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-card-text class="mt-1">* Please note that the cycle only ends when the <span class="text-danger"><i>End Cycle</i></span>
        button below is clicked. <br />
        <small> <b>It does not end automatically once the deadline is reached.</b> </small>
      </b-card-text>
    </b-card-body>
    <b-button activev-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="danger" @click="endCycle">
      <feather-icon icon="AlertTriangleIcon" class="mr-50" size="16" />
      <span class="align-middle">End Cycle</span>
    </b-button>
  </b-card>
</template>

<script>
import {
  BCard, BImg, BCardBody, BCardText, BCardTitle, BMedia, BMediaAside, BAvatar, BAvatarGroup, VBTooltip, BMediaBody, BRow, BCol, BButton, BCardHeader,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const $strokeColor = '#ebe9f1'
const $textHeadingColor = '#5e5873'
const $goalStrokeColor2 = '#51e5a8'

export default {
  components: {
    BCard,
    BImg,
    BCardBody,
    BCardText,
    BCardTitle,
    BMedia,
    BMediaAside,
    BAvatar,
    BAvatarGroup,
    BMediaBody,
    BRow,
    BCol,
    BButton,
    VueApexCharts,
    BCardHeader,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({
        nominationsStopped: false, 
      }),
    },
  },
  data() {
    return {
      series: [0],
      goalOverviewRadialBar: {
        chart: {
          height: 245,
          type: 'radialBar',
          sparkline: {
            enabled: true,
          },
          dropShadow: {
            enabled: true,
            blur: 3,
            left: 1,
            top: 1,
            opacity: 0.1,
          },
        },
        colors: ['#00ff00'], // Starting color (green)
        plotOptions: {
          radialBar: {
            offsetY: 0, // -10
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '75%',
            },
            track: {
              background: $strokeColor,
              strokeWidth: '50%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              value: {
                color: $textHeadingColor,
                fontSize: '2rem',
                fontWeight: '600',
              },
            },
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: ['#ff0000', '#ffff00', '#00ff00'], // Red to yellow to green
            inverseColors: false,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 50, 100], // Intermediate stop at 50% for yellow
          },
        },
        stroke: {
          lineCap: 'round',
        },
        grid: {
          padding: {
            bottom: 10,
          },
        },
      },
      chartOptions: {
        plotOptions: {
          radialBar: {
            size: 150,
            offsetY: 20,
            startAngle: -150,
            endAngle: 150,
            hollow: {
              size: '65%',
            },
            track: {
              background: '#fff',
              strokeWidth: '100%',
            },
            dataLabels: {
              name: {
                show: false,
              },
              // name: {
              //   offsetY: -5,
              //   color: '#5e5873',
              //   fontSize: '1rem',
              // },
              value: {
                offsetY: 15,
                color: '#5e5873',
                fontSize: '1.714rem',
              },
            },
          },
        },
        colors: [$themeColors.danger],
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            type: 'horizontal',
            shadeIntensity: 0.5,
            gradientToColors: [$themeColors.success],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100],
          },
        },
        stroke: {
          dashArray: 3,
        },
        labels: [],
      },
      daysElapsed: 0,
      daysLeft: 0
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'data.deadline': function () {
      try {
        const startDate = new Date(this.data.startDate);
        const deadline = new Date(this.data.deadline);
        const currentDate = new Date();

        if (currentDate > deadline) {
          this.$refs.completionChart.updateSeries([100], true);
          this.daysElapsed = Math.floor((deadline - startDate) / (1000 * 60 * 60 * 24));
          this.daysLeft = 0;
          return;
        }

        const totalDuration = deadline - startDate;
        const elapsedDuration = currentDate - startDate;

        const completion = Math.min(100, ((elapsedDuration / totalDuration) * 100).toFixed(0));
        this.$refs.completionChart.updateSeries([completion], true);

        this.daysElapsed = Math.floor(elapsedDuration / (1000 * 60 * 60 * 24));
        this.daysLeft = Math.ceil((deadline - currentDate) / (1000 * 60 * 60 * 24));
      } catch (error) {
        console.error(error);
      }
    },
  },

  computed: {
    monthName() {
      const date = new Date(this.data.deadline)
      return date.toLocaleString('default', { month: 'long' }).toUpperCase()
    },
    day() {
      const date = new Date(this.data.deadline)
      return date.getDate()
    },
    year() {
      const date = new Date(this.data.deadline)
      return date.getFullYear()
    },
    cycleStatus() {
      return this.data.isActive ? 'Current active cycle.' : 'Cycle has ended.'
    },

  },
  methods: {
    formatDate(date) {
      const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' }
      return new Date(date).toLocaleDateString(undefined, options)
    },
    async toggleNominations() {
      try {
        const cycle = await this.$http.put(`/cycles/toggleNominations`)
        
        this.$emit('update-cycleData', cycle.data)
        
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: `Nominations ${this.data.nominationsStopped ? "Restored" : "Stopped"} Successfully!`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
      } catch (err) {
        console.log(err)
        const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Error',
            text: errorMessage,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    },
    endCycle() {
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm(`Please confirm that you would like to end the current cycle: ${this.data.title}`, {
          title: 'Are you sure?',
          size: 'md',
          okVariant: 'danger',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.$http.put(`/cycles/${this.data._id}/complete`)
              .then(() => {
                console.log('Ending current cycle...')
                this.$emit('set-inactive')
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Cycle Ended Successfully!',
                    icon: 'CheckIcon',
                    variant: 'success',
                  }
                })
              })
              .catch((err) => {
                const errorMessage = (err.response && err.response.data && err.response.data.error) || 'Something went wrong.'
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: 'Error',
                    text: errorMessage,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              })

          }
        })
    }
  }
}
</script>
